jQuery(document).ready(function ($) {
    $(".big-slider").slick({
        arrows: false,
        autoplay: true,
        fade: true,
        autoplaySpeed: 6000,
        dots: true,
        speed: 1000,
        dotsClass: "slide-dots",
    });
    $(".hero-slider").slick({
        arrows: false,
        autoplay: true,
        fade: true,
        autoplaySpeed: 3000,
        speed: 1000,
    });
    $(".slider.info-box").slick({
        arrows: false,
        autoplay: true,
        fade: true,
        autoplaySpeed: 6000,
        dots: true,
        speed: 500,
        dotsClass: "slide-dots",
    });
    $(".slider.single-item").slick({
        arrows: false,
        autoplay: false,
        fade: true,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        asNavFor: '.multiple-items',
    });
    $(".slider.multiple-items").slick({
        arrows: true,
        autoplay: false,
        fade: false,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        asNavFor: '.single-item',
        focusOnSelect: true,
        centerMode: true,
    });
    $('.service-slides').click(function (e) {
        $('.single-item').removeClass('hidden');
        $('.single-item').addClass('visible');
    });
});
